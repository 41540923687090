export enum Currencies {
  THB = 'THB',
  USD = 'USD',
  AUD = 'AUD',
  HKD = 'HKD',
  CAD = 'CAD',
  NZD = 'NZD',
  SGD = 'SGD',
  EUR = 'EUR',
  HUF = 'HUF',
  CHF = 'CHF',
  GBP = 'GBP',
  UAH = 'UAH',
  JPY = 'JPY',
  CZK = 'CZK',
  DKK = 'DKK',
  ISK = 'ISK',
  NOK = 'NOK',
  SEK = 'SEK',
  HRK = 'HRK',
  RON = 'RON',
  BGN = 'BGN',
  TRY = 'TRY',
  ILS = 'ILS',
  CLP = 'CLP',
  PHP = 'PHP',
  MXN = 'MXN',
  ZAR = 'ZAR',
  BRL = 'BRL',
  MYR = 'MYR',
  RUB = 'RUB',
  IDR = 'IDR',
  INR = 'INR',
  KRW = 'KRW',
  CNY = 'CNY',
  XDR = 'XDR',
}
